import { useEffect, useState } from 'react';
import { ContractFunction, ResultsParser } from '@multiversx/sdk-core/out';
import { ProxyNetworkProvider } from '@multiversx/sdk-network-providers/out';

import { useGetActiveTransactionsStatus, useGetNetworkConfig } from 'hooks';
import { DistributionAddress } from 'types';
import { smartContract } from './smartContract';

const resultsParser = new ResultsParser();

export const useGetDistributionAddressList = () => {
  const { network } = useGetNetworkConfig();
  const [distributionAdresses, setDistributionAdresses] = useState<
    DistributionAddress[]
  >([]);
  const { success } = useGetActiveTransactionsStatus();

  const proxy = new ProxyNetworkProvider(network.apiAddress);

  const getDistributionAddressList = async () => {
    try {
      const query = smartContract.createQuery({
        func: new ContractFunction('getDistributionAddresses')
      });
      const queryResponse = await proxy.queryContract(query);

      const endpointDefinition = smartContract.getEndpoint(
        'getDistributionAddresses'
      );

      const { firstValue: addresses } = resultsParser.parseQueryResponse(
        queryResponse,
        endpointDefinition
      );

      if (addresses?.valueOf().length === 0) return;

      const decodedArray: DistributionAddress[] = [];

      for (const item of addresses?.valueOf()) {
        decodedArray.push({
          address: item.address.toString(),
          percent: Number(item.percent)
        });
      }
      setDistributionAdresses(decodedArray);
    } catch (err) {
      console.error('Unable to call getLastDistribution', err);
    }
  };

  useEffect(() => {
    getDistributionAddressList();
  }, [success]);

  return distributionAdresses;
};
