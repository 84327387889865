import React, { useEffect, useState } from 'react';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Address, TransactionPayload } from '@multiversx/sdk-core/out';
import { contractAddress, ownerAddress } from 'config';
import { refreshAccount, sendTransactions, truncateString } from 'helpers';
import { useGetAccountInfo } from 'hooks';
import { useGetDistributionAddressList } from '../hooks';
import AddAddressModal from './AddAddressModal';
import UpdateAddressModal from './UpdateAddressModal';

const DistributionAdressesList = () => {
  const { address } = useGetAccountInfo();
  const distributionAddresses = useGetDistributionAddressList();
  const [showAddAddress, setShowAddAddress] = useState<boolean>(false);
  const [showUpdateAddress, setShowUpdateAddress] = useState<boolean>(false);
  const [addressCopied, setAddressCopied] = useState<string>();
  const [addressSelected, setAddressSelected] = useState<string>();

  const copyAddress = (addressToCopy: string) => {
    navigator.clipboard.writeText(addressToCopy);
    setAddressCopied(addressToCopy);
  };

  const handleCloseAddAddressModal = () => {
    setShowAddAddress(false);
  };

  const handleOpenAddressModal = (handleAddress: string) => {
    if (!handleAddress) return;
    setAddressSelected(address);
    setShowUpdateAddress(true);
  };

  const handleCloseUpdateAddressModal = () => {
    setShowUpdateAddress(false);
    setAddressSelected(undefined);
  };

  const remove = async (addressToRemove: string) => {
    if (!address) return;
    const tx = {
      value: 0,
      data: new TransactionPayload(
        'removeDistributionAddress@' + new Address(addressToRemove).hex()
      ),
      receiver: contractAddress,
      gasLimit: '6000000'
    };
    await refreshAccount();

    await sendTransactions({
      transactions: tx,
      transactionsDisplayInfo: {
        processingMessage: 'Processing transaction',
        errorMessage: 'An error has occured',
        successMessage: 'Transaction successful'
      },
      redirectAfterSign: false
    });
  };

  useEffect(() => {
    setTimeout(() => {
      setAddressCopied(undefined);
    }, 1000);
  }, [addressCopied]);

  return (
    <div className='col-12 col-md-6 mt-2'>
      <div className='card p-3'>
        <h2 className='text-center'>Distribution Wallets</h2>
        {address === ownerAddress && (
          <div className='mb-3'>
            <button
              className='btn btn-primary d-block mx-auto'
              onClick={() => setShowAddAddress(true)}
            >
              Add
            </button>
          </div>
        )}
        <div>
          {distributionAddresses.map((item, index) => (
            <div key={item.address + index} className='mb-2'>
              <div className='d-block d-lg-flex justify-content-between align-items-center'>
                <div className='d-flex'>
                  <h5 className='mr-1'>{truncateString(item.address, 20)}</h5>
                  <FontAwesomeIcon
                    icon={faCopy}
                    style={{ cursor: 'pointer' }}
                    color={addressCopied === item.address ? 'gray' : 'black'}
                    onClick={() => copyAddress(item.address)}
                  />
                </div>
                <h5>{item.percent}%</h5>
                {address === ownerAddress && (
                  <div className='d-block d-lg-flex justify-content-center align-items-center'>
                    <button
                      className='btn btn-success mr-1'
                      onClick={() => handleOpenAddressModal(item.address)}
                    >
                      Update
                    </button>
                    <button
                      className='btn btn-danger'
                      onClick={() => remove(item.address)}
                    >
                      Remove
                    </button>
                  </div>
                )}
              </div>
              <hr />
            </div>
          ))}
        </div>
      </div>
      <AddAddressModal
        show={showAddAddress}
        handleClose={handleCloseAddAddressModal}
      />
      {addressSelected && (
        <UpdateAddressModal
          show={showUpdateAddress}
          address={addressSelected}
          handleClose={handleCloseUpdateAddressModal}
        />
      )}
    </div>
  );
};

export default DistributionAdressesList;
