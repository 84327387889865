import { useEffect, useState } from 'react';
import { ContractFunction, ResultsParser } from '@multiversx/sdk-core/out';
import { ProxyNetworkProvider } from '@multiversx/sdk-network-providers/out';

import { useGetNetworkConfig } from 'hooks';
import { smartContract } from './smartContract';

const resultsParser = new ResultsParser();

export const useGetMaxSupply = () => {
  const { network } = useGetNetworkConfig();
  const [maxSupply, setMaxSupply] = useState<number>(0);

  const proxy = new ProxyNetworkProvider(network.apiAddress);

  const getMaxSupply = async () => {
    try {
      const query = smartContract.createQuery({
        func: new ContractFunction('getMaxSupply')
      });
      const queryResponse = await proxy.queryContract(query);

      const endpointDefinition = smartContract.getEndpoint('getMaxSupply');

      const { firstValue: lastTime } = resultsParser.parseQueryResponse(
        queryResponse,
        endpointDefinition
      );
      const lastTimeValue = lastTime?.valueOf()?.toString();

      if (lastTimeValue) setMaxSupply(Number(lastTimeValue));
    } catch (err) {
      console.error('Unable to call getLastDistribution', err);
    }
  };

  useEffect(() => {
    getMaxSupply();
  }, []);

  return maxSupply;
};
