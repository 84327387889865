import { useEffect, useState } from 'react';
import { ContractFunction, ResultsParser } from '@multiversx/sdk-core/out';
import { ProxyNetworkProvider } from '@multiversx/sdk-network-providers/out';

import { useGetActiveTransactionsStatus, useGetNetworkConfig } from 'hooks';
import { smartContract } from './smartContract';

const resultsParser = new ResultsParser();

export const useGetDays = () => {
  const { network } = useGetNetworkConfig();
  const [days, setDays] = useState<number>(0);
  const { success } = useGetActiveTransactionsStatus();

  const proxy = new ProxyNetworkProvider(network.apiAddress);

  const getDays = async () => {
    try {
      const query = smartContract.createQuery({
        func: new ContractFunction('getDays')
      });
      const queryResponse = await proxy.queryContract(query);

      const endpointDefinition = smartContract.getEndpoint('getDays');

      const { firstValue: amount } = resultsParser.parseQueryResponse(
        queryResponse,
        endpointDefinition
      );
      const daysValue = amount?.valueOf()?.toString();

      if (daysValue) setDays(Number(daysValue));
    } catch (err) {
      console.error('Unable to call getLastDistribution', err);
    }
  };

  useEffect(() => {
    getDays();
  }, [success]);

  return days;
};
