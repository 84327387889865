import React from 'react';
import DistributionAdressesList from './components/DistributionAdressesList';
import DistributionTimers from './components/DistributionTimers';
import Info from './components/Info';
import { useGetCurrentSupply, useGetDays, useGetMaxSupply } from './hooks';

export const Dashboard = () => {
  const maxSupply = useGetMaxSupply();
  const currentSupply = useGetCurrentSupply();
  const days = useGetDays();
  const dailyDistribution = (maxSupply - currentSupply) / days;

  return (
    <div className='container-fluid'>
      <DistributionTimers currentDistribution={dailyDistribution} />
      <div className='row'>
        <Info days={days} currentSupply={currentSupply} maxSupply={maxSupply} />
        <DistributionAdressesList />
      </div>
    </div>
  );
};
