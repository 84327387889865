import React, { useState, useEffect } from 'react';

export const Timer = ({
  deadline,
  onlyTime = true,
  overText,
  overFunc
}: {
  deadline: number;
  onlyTime?: boolean;
  overText: string;
  overFunc?: () => void;
}) => {
  const [timer, setTimer] = useState(deadline - Math.floor(Date.now() / 1000));
  useEffect(() => {
    const timerInterval = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    return () => {
      clearInterval(timerInterval);
    };
  }, []);

  useEffect(() => {
    if (timer < 1) {
      if (overFunc) overFunc();
    }
  }, [timer]);

  const days = Math.floor(timer / 86400);
  const hours = Math.floor((timer % 86400) / 3600);
  const minutes = Math.floor((timer % 3600) / 60);
  const seconds = Math.floor(timer % 60);

  if (onlyTime)
    return (
      <div>
        {timer <= 0 ? (
          <p className='text-center'>{overText}</p>
        ) : (
          <h4 className='text-center mission-card-box-left-time mb-3 mb-lg-4'>
            {minutes.toString().padStart(2, '0')}:
            {seconds.toString().padStart(2, '0')}
          </h4>
        )}
      </div>
    );

  return (
    <div>
      {timer <= 0 ? (
        <p className='text-center'>{overText}</p>
      ) : (
        <h4 className='text-center mission-card-box-left-time mb-3 mb-lg-4'>
          {days} days {hours.toString().padStart(2, '0')} hours{' '}
          {minutes.toString().padStart(2, '0')} minutes{' '}
          {seconds.toString().padStart(2, '0')} seconds
        </h4>
      )}
    </div>
  );
};
