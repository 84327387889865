import React, { useState } from 'react';
import { Address, TransactionPayload } from '@multiversx/sdk-core/out';
import { Button, Form, Modal } from 'react-bootstrap';
import { numberToHex } from 'ts-apc-utils';
import { contractAddress } from 'config';
import { refreshAccount, sendTransactions, truncateString } from 'helpers';

const UpdateAddressModal = ({
  show,
  address,
  handleClose
}: {
  show: boolean;
  address: string;
  handleClose: () => void;
}) => {
  const [percent, setPercent] = useState<number>();

  const handleChangePercent = (percentValue: number) => {
    if (percentValue > 0 && percentValue <= 100) {
      setPercent(percentValue);
    }
  };

  const sendTx = async () => {
    if (percent === undefined && percent === 0) return;
    if (!percent) return;

    const tx = {
      value: 0,
      data: new TransactionPayload(
        'updateDistributionAddress@' +
          new Address(address).hex() +
          '@' +
          numberToHex(percent)
      ),
      receiver: contractAddress,
      gasLimit: '6000000'
    };
    await refreshAccount();

    await sendTransactions({
      transactions: tx,
      transactionsDisplayInfo: {
        processingMessage: 'Processing transaction',
        errorMessage: 'An error has occured',
        successMessage: 'Transaction successful'
      },
      redirectAfterSign: false
    });
    handleClose();
    setPercent(undefined);
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>Update address</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5>Set percent for:</h5>
        <p>{truncateString(address, 12)}</p>
        <Form.Control
          type='number'
          placeholder='Type percent here...'
          value={percent}
          onChange={(e) => handleChangePercent(Number(e.currentTarget.value))}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={handleClose}>
          Close
        </Button>
        <Button variant='primary' onClick={sendTx}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UpdateAddressModal;
