import React, { useEffect, useState } from 'react';
import { TransactionPayload } from '@multiversx/sdk-core/out';
import { Button, Form, Modal } from 'react-bootstrap';
import { numberToHex } from 'ts-apc-utils';
import { contractAddress } from 'config';
import { refreshAccount, sendTransactions } from 'helpers';

const UpdateDaysModal = ({
  show,
  currentDays,
  handleClose
}: {
  show: boolean;
  currentDays: number;
  handleClose: () => void;
}) => {
  const [days, setDays] = useState<number>(currentDays);

  const handleChangeDays = (amount: number) => {
    if (amount > 0) {
      setDays(amount);
    }
  };

  const sendTx = async () => {
    if (days === undefined && days === 0) return;
    if (!days) return;

    const tx = {
      value: 0,
      data: new TransactionPayload('updateDays@' + numberToHex(days)),
      receiver: contractAddress,
      gasLimit: '6000000'
    };
    await refreshAccount();

    await sendTransactions({
      transactions: tx,
      transactionsDisplayInfo: {
        processingMessage: 'Processing transaction',
        errorMessage: 'An error has occured',
        successMessage: 'Transaction successful'
      },
      redirectAfterSign: false
    });
    handleClose();
  };

  useEffect(() => {
    if (currentDays) setDays(currentDays);
  }, [currentDays]);

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>Update Days</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Control
          type='number'
          placeholder='Type days here...'
          value={days}
          onChange={(e) => handleChangeDays(Number(e.currentTarget.value))}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={handleClose}>
          Close
        </Button>
        <Button variant='primary' onClick={sendTx}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UpdateDaysModal;
