export const contractAddress =
  'erd1qqqqqqqqqqqqqpgqaxa5zdhglagqhvlrjw2tja8vtheejp6jwmfsrt8unq';

export const ownerAddress =
  'erd1szcgm7vq3tmyxfgd4wd2k2emh59az8jq5jjpj9799a0k59u0wmfss4vw3v';

export const dAppName = 'Distribution';

export const walletConnectV2ProjectId = '9b1a9564f91cb659ffe21b73d5c4e2d8';

export const apiTimeout = 6000;
export const transactionSize = 15;
export const TOOLS_API_URL = 'https://tools.multiversx.com';
/**
 * Calls to these domains will use `nativeAuth` Baerer token
 */
export const sampleAuthenticatedDomains = [TOOLS_API_URL];
