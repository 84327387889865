import { useEffect, useState } from 'react';
import { ContractFunction, ResultsParser } from '@multiversx/sdk-core/out';
import { ProxyNetworkProvider } from '@multiversx/sdk-network-providers/out';

import { useGetNetworkConfig } from 'hooks';
import { smartContract } from './smartContract';

const resultsParser = new ResultsParser();

export const useGetCurrentSupply = () => {
  const { network } = useGetNetworkConfig();
  const [currentSupply, setCurrentSupply] = useState<number>(0);

  const proxy = new ProxyNetworkProvider(network.apiAddress);

  const getCurrentSupply = async () => {
    try {
      const query = smartContract.createQuery({
        func: new ContractFunction('getSupply')
      });
      const queryResponse = await proxy.queryContract(query);

      const endpointDefinition = smartContract.getEndpoint('getSupply');

      const { firstValue: lastTime } = resultsParser.parseQueryResponse(
        queryResponse,
        endpointDefinition
      );
      const lastTimeValue = lastTime?.valueOf()?.toString();

      if (lastTimeValue) setCurrentSupply(Number(lastTimeValue));
    } catch (err) {
      console.error('Unable to call getLastDistribution', err);
    }
  };

  useEffect(() => {
    getCurrentSupply();
  }, []);

  return currentSupply;
};
