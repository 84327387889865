export function truncateString(str: string, maxLength: number) {
  if (str.length <= maxLength) {
    return str; // Nu este nevoie să trunchiem
  }

  const prefixLength = Math.floor(maxLength / 2);
  const suffixLength = maxLength - 3 - prefixLength;

  const prefix = str.slice(0, prefixLength);
  const suffix = str.slice(-suffixLength);

  return prefix + '...' + suffix;
}
