import React, { FC, useState } from 'react';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ownerAddress } from 'config';
import { useGetAccountInfo } from 'hooks';
import UpdateDaysModal from './UpdateDaysModal';

const Info: FC<Props> = ({ days, maxSupply, currentSupply }) => {
  const { address } = useGetAccountInfo();
  const [show, setShow] = useState<boolean>(false);

  const handleOpen = () => setShow(true);
  const handleClose = () => setShow(false);

  return (
    <div className='col-12 col-md-6'>
      <div className='card'>
        <div className='card-body'>
          <h2 className='text-center'>Info</h2>
          <h4>Supply:</h4>
          <div className='d-block d-lg-flex justify-content-between align-items-center'>
            <h5 className='text-center'>Max: {maxSupply.toLocaleString()}</h5>
            <h5 className='text-center'>
              Current: {currentSupply.toLocaleString()}
            </h5>
            <h5 className='text-center'>
              Remaining: {(maxSupply - currentSupply).toLocaleString()}
            </h5>
          </div>
          <h4>Growth factor:</h4>
          <div className='d-flex'>
            <h5 className='mr-2'>{days.toLocaleString()}</h5>
            {address === ownerAddress && (
              <FontAwesomeIcon
                icon={faEdit}
                onClick={handleOpen}
                style={{ cursor: 'pointer' }}
              />
            )}
          </div>
        </div>
      </div>
      <UpdateDaysModal
        show={show}
        handleClose={handleClose}
        currentDays={days}
      />
    </div>
  );
};

interface Props {
  days: number;
  currentSupply: number;
  maxSupply: number;
}

export default Info;
