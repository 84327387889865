import React, { FC } from 'react';
import { Timer } from 'components/Timer';
import { useGetLastDistribution } from '../hooks/useGetLastDistribution';

const DistributionTimers: FC<Props> = ({ currentDistribution }) => {
  const lastDistribution = useGetLastDistribution();
  const ONE_DAY_IN_MILISECONDS = 86400;

  return (
    <div className='row mt-3'>
      <div className='col-12'>
        <h2 className='text-center'>
          The last distribution was:{' '}
          {new Date(lastDistribution * 1000).toLocaleDateString('ro-RO', {
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
          })}
        </h2>
        <h3 className='text-center mt-3'>
          Next distribution: {currentDistribution.toLocaleString()}
        </h3>
        {lastDistribution && (
          <Timer
            onlyTime={false}
            deadline={lastDistribution + ONE_DAY_IN_MILISECONDS}
            overText='Waiting...'
          />
        )}
      </div>
    </div>
  );
};

interface Props {
  currentDistribution: number;
}

export default DistributionTimers;
