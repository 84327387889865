import { useEffect, useState } from 'react';
import { ContractFunction, ResultsParser } from '@multiversx/sdk-core/out';
import { ProxyNetworkProvider } from '@multiversx/sdk-network-providers/out';

import { useGetNetworkConfig } from 'hooks';
import { smartContract } from './smartContract';

const resultsParser = new ResultsParser();

export const useGetLastDistribution = () => {
  const { network } = useGetNetworkConfig();
  const [lastDistribution, setLastDistribution] = useState<number>(0);

  const proxy = new ProxyNetworkProvider(network.apiAddress);

  const getLastDistribution = async () => {
    try {
      const query = smartContract.createQuery({
        func: new ContractFunction('getLastDistribution')
      });
      const queryResponse = await proxy.queryContract(query);

      const endpointDefinition = smartContract.getEndpoint(
        'getLastDistribution'
      );

      const { firstValue: supply } = resultsParser.parseQueryResponse(
        queryResponse,
        endpointDefinition
      );
      const supplyValue = supply?.valueOf()?.toString();

      if (supplyValue) setLastDistribution(Number(supplyValue));
    } catch (err) {
      console.error('Unable to call getLastDistribution', err);
    }
  };

  useEffect(() => {
    getLastDistribution();
  }, []);

  return lastDistribution;
};
